* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Barlow Condensed", sans-serif;
  overflow-x: hidden;
}

p{
    font-family: 'Roboto', sans-serif;
}

