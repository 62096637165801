.sectors {
  background-color: #3e3e3f;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.sectors>h1{
    color: #f4c75b;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    font-size: 44px;
}
