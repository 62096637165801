.header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  flex-direction: column;
  /* height: 20vh; */
  /* width: 90%; */
  margin-top: 20px;
  text-align: center;
}

.header > h1 {
  color: #f4c75b;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 44px;
}

.header > p {
  color: #3e3e3f;
  font-size: 24px;
}
