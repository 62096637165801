.section1 {
  background-color: #3e3e3f;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section1 > h1 {
  color: #f4c75b;
  margin: 20px;
  text-transform: uppercase;
  font-size: 44px;
  text-align: center
}

.section1 > p {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  margin: 20px;
  width: 70%;
  line-height: 1.5;
}

.section2 {
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section2 > h1 {
  color: #f4c75b;
  margin:20px;
  text-transform: uppercase;
  font-size: 44px;
  text-align: center;
}

.section2 > p {
  color: #3e3e3f;
  text-align: center;
  font-size: 20px;
  margin: 20px;
  width: 70%;
  line-height: 1.5;
}

.section3 {
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section3 > h1 {
  color: #f4c75b;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 44px;
  text-align: center
}

.section3 > p {
  color: #3e3e3f;
  text-align: center;
  font-size: 20px;
  margin: 20px;
  width: 70%;
  line-height: 1.5;
  margin-bottom: 50px;
}
