
.button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.buttonSelected {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #6c6d6f;
  color: white;
  cursor: pointer;
}
