.login {
  background-color: white;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.login__container {
  width: 50%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid lightgray;
  justify-content: center;
  align-items: center;
}
.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}
.login__container > form > h5 {
  margin-bottom: 5px;
}
.login__container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login__container > p {
  margin-top: 15px;
  font-size: 12px;
}

.form_table{
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* tr:nth-child(even) {
  background-color: #dddddd;
} */

