.card {
  display: flex;
  width: 60%;
  border: 1px solid #3e3e3f;
  border-radius: 17px 0px 0px 17px;
  margin-top: 30px;
}

.card__image {
  width: 300px;
  height: 215px;
  border-radius: 17px 0px 0px 17px;
}

.card__section {
  padding: 10px;
  padding-left: 20px;
  border-radius: 0px 17px 17px 0px;
  width: 65%;
}

.card__section>p{
    margin-top: 5px;
    font-size: 15px;
}
