.navbar {
  background: black;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* position: sticky; */
  top: 0;
  z-index: 999;
  width: 100vw;
  color: whitesmoke;

  
}

.navbar > a {
  text-decoration: none;
  color: white;
  margin: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3em;
  
}
.navbar > a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {

  .navbar > a {
  font-size: 0.8em;
}
}
/* .navbar__items{
    display: flex;
}

.navbar__items > ul {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  color: whitesmoke;
  justify-content: space-between;
} */
