.participate {
  background-color: #3e3e3f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.participateButton {
  background-color: #dc4351;
  padding: 15px 50px;
  border-radius: 30px;
  color: whitesmoke;
  font-size: 24px;
  border: 1px solid #dc4351;
  outline: none;
  cursor: pointer;
  margin-bottom: 100px;
}

.participate > h1 {
  margin-top: 100px;
  color: #f4c75b;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 100px;
  text-transform: uppercase;
  font-size: 66px;
  text-align: center;
}

.participatePara {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  margin: 20px;
  width: 65%;
  line-height: 1.5;
}

.participate__details {
  display: flex;
  font-size: 20px;
}

.participate__left {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  width: 50%;
}
/* .participate__left > p {
  text-align: center;
  margin: 20px;
} */
.participate__left > h1 {
  color: #f4c75b;
  padding-bottom: 20px;
}

.participate__right {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  width: 50%;
}
/* .participate__right > p {
  text-align: center;
  margin: 20px;
} */

.participate__right > h1 {
  color: #f4c75b;
  padding-bottom: 20px;
}
